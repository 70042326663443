import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts";
import SEO from "../components/seo";
import PropTypes from "prop-types";
import PageBuilder from "../modules/page-builder";
import Header from "../modules/header";
import BasicImage from "../elements/basic-image";
import CapsLabel from "../elements/caps-label";
import CTA from "../modules/cta";

export const query = graphql`
  query CourseTemplateQuery($id: String!) {
    course: sanityCourse(id: { eq: $id }) {
      title
      excerpt
      slug {
        current
      }

      introTitle
      introDescription
      introImage {
        asset {
          _id
          altText
          metadata {
            lqip
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      ...courseBuilderFields
      image {
        asset {
          _id
          altText
          metadata {
            lqip
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      seo {
        title
        description
        keywords
        image {
          asset {
            _id
            altText
          }
        }
      }
      breadcrumbs {
        title
        slug
      }
      taxonomy {
        topic {
          title
          parentTopic
        }
        tags {
          title
        }
      }
      subjects {
        _id
        title
        _createdAt
        title
        slug {
          current
        }
        excerpt
        image {
          asset {
            url
          }
        }
        subjectSection {
          title
          intro
          video {
            url {
              url
            }
          }
          transcript
          file {
            type
            file {
              title
            }
          }
        }
      }
    }
  }
`;

const CourseTemplate = props => {
  const { data } = props;
  const course = data.course;

  return (
    <Layout className="article">
      <SEO
        title={course.title}
        excerpt={course.excerpt}
        image={course.image}
        seo={course.seo}
        taxonomy={course.taxonomy}
      />
      <Header
        title={course.title}
        image={course.image}
        breadcrumbs={course.breadcrumbs}
        backgroundColour={"orange"}
      />
      <div className="container p-course-page-container">
        {/* TODO: move to CSS and make responsive */}
        <section
          className="p-course-page-overview"
          style={{ marginBottom: "6rem", marginTop: "4rem" }}
        >
          <div id="intro" className="p-course-page-intro">
            <div id="intro-text" className="p-course-page-intro-text ">
              <CapsLabel text="Welcome" />
              <span className="h3">{course.introTitle}</span>
              <p className="small-body">{course.introDescription} </p>
            </div>
            <div id="intro-image" className="p-course-page-intro-image">
              <div className="e-image-container e-landscape-image-container">
                <BasicImage image={course.introImage} />
              </div>
            </div>
          </div>
          <div id="course-subjects">
            <h4>Get Started</h4>
            <div className="p-course-page-subjects-grid">
              {course?.subjects?.map((subject, index) => (
                <a
                  href={`/courses/${course.slug.current}/${subject.slug.current}`}
                  style={{
                    cursor: "pointer",
                  }}
                  className="e-area-tile"
                  key={subject.id}
                >
                  <h3>
                    {index + 1}. {subject.title}
                  </h3>
                </a>
              ))}
            </div>
          </div>
        </section>

        <section
          id="more-course-page"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <PageBuilder builder={course.pageBuilder} />
        </section>
        <CTA
          title={"Start learning today"}
          text={course.subjects[0].title}
          link={{
            text: "Start",
            link: `/courses/${course.slug.current}/${course.subjects[0].slug.current}`,
            linkType: "internal",
          }}
        />
      </div>
    </Layout>
  );
};

CourseTemplate.propTypes = {
  data: PropTypes.object,
};

export default CourseTemplate;
